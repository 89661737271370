<template>
  <span>
    <v-card>
      <v-card-title>
        Somatório Geral
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col
            v-for="item in items"
            :key="item.indicador"
            class="py-0"
            cols="12"
            sm="6"
            md="3"
            xl="2"
          >
            <v-card :height="125" class="my-3">
              <v-card-title style="display: block;">
                <h4>{{ item.indicador | toUpperCase }}</h4>
                <div class="body-2">{{ item.descricao }}</div>
              </v-card-title>
              <v-card-text>
                <div
                  class="display-3"
                  style="position: absolute; bottom: 0; hyphens: auto;"
                >
                  {{
                    dados[item.indicador] ||
                      "-" | parseNumberToFloatBrIgnoringNaN
                  }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  export default {
    name: "ChartsGeralServicos",
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      tipo_servico: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      items: [
        { indicador: "serv_006", descricao: "Qtd de serviços realizados" },
        {
          indicador: "serv_008",
          descricao: "Qtd de serviços realizados com descumprimento de prazo",
        },
        { indicador: "serv_010", descricao: "Qtd de serviços solicitados" },
        {
          indicador: "serv_011",
          descricao: "Qtd de serviços ainda não realizados",
        },
        { indicador: "serv_012", descricao: "Qtd de serviços suspensos" },
        {
          indicador: "serv_013",
          descricao:
            "Qtd de serviços pendentes de atend e com descumprimento do prazo",
        },
        { indicador: "serv_015", descricao: "Montante pago em compensações" },
      ],
      dados: {},
    }),
    mounted() {
      this.$emit("setComponentIsMounted", "chartsGeralServicos");
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        tipo_servico = this.tipo_servico
      ) {
        this.$emit("updateLoadingDados", true);

        return IndicadoresServicosV2Service.getSomatorioGeral(
          competencia_de,
          competencia_ate,
          tipo_servico
        )
          .then((res) => {
            this.dados = res;
          })
          .catch((err) => {
            this.$toast.error(
              "Erro ao recuperar dados do Somatório Geral.",
              "",
              {
                position: "topRight",
                timeout: 5000,
              }
            );
            throw err;
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
    },
  };
</script>

<style></style>
